import React from 'react'
import Seo from '../components/seo/seo'
import Layout from '../components/layouts/layout'
import './about-page.sass'

const Page = () => (
  <Layout className="About u-wrap u-wrap--extra-left">
    <Seo
      title="About - Maison Hanko"
      description="Nexus has a long and successful history of curating and collaborating with independent Tokyo-based talent and anime studios." />
    <div className="About__hero">
      <h1 className="About__hero__title_eng">
        About
      </h1>
    </div>
    <div className="About__grid">
      <h2 className="About__subtitle">
        Maison Hanko is a creative talent house specialising in Japanese animation and design, for entertainment and branded content, backed by Nexus Studios’ global production offices and animation studios in London, Los Angeles and Sydney.
      </h2>
      <div className="About__text">
        <p>We collaborate with outstanding Japan-based directors and illustrators for development, production and co-production.</p>
        <p>Like all good anime characters, Maison Hanko has its own origin backstory.<br/> <br/>Nexus Studios itself, more than twenty years ago, began as part of a Japanese company. Founders Chris O’Reilly and Charlotte Bavasso were inspired by the anime scene which Chris had encountered first hand while living in Japan.</p> 
        <p>They began short form collaborations with Tokyo based talent and co-productions with  some of the very best anime studios, including Production IG (Ghost in the Shell) and Studio 4&#176;C (Akira).</p>
        <p>They brought anime to the BBC and curated festival screenings of the best of Japanese talent. This long-standing connection to Japan and anime lives on in the form of Maison Hanko today, providing unique opportunities for collaboration and access to Japan’s unique and world class talent.</p>
      </div>
    </div>
  </Layout>
)

export default Page
